import React from "react";
import { pathUrl } from "./path-url";

// private
const page500 = React.lazy(() => import("../containers/page-500"));
const page400 = React.lazy(() => import("../containers/page-400"));

const booking = React.lazy(() => import("../containers/booking/index"));
const schedule = React.lazy(() => import("../containers/schedule/index"));
const bookingPrice = React.lazy(() => import("../containers/booking-price"));
const bookingPriceDetail = React.lazy(() => import("../containers/booking-price/detail"));

export interface IRouter {
  path: string;
  title: string;
  component: any;
}

export const pageRouter: IRouter[] = [
  {
    path: pathUrl.BOOKING,
    title: "Booking",
    component: booking,
  },
  {
    path: pathUrl.SCHEDULE,
    title: "schedule timeline",
    component: schedule,
  },
  {
    path: pathUrl.BOOKING_PRICE.INDEX,
    title: "booking price",
    component: bookingPrice,
  },
  {
    path: pathUrl.BOOKING_PRICE.DETAIL,
    title: "booking price detail",
    component: bookingPriceDetail,
  },
  {
    path: pathUrl.PAGE_500,
    title: "Page 500",
    component: page500,
  },
  {
    path: pathUrl.PAGE_400,
    title: "Page 400",
    component: page400,
  },
];






