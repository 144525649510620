import { pathUrl } from "./path-url";

export const leftMenu = [

  {
    label: "lbl_menu_booking",
    enable: true,
    permissions: [],
    url: pathUrl.BOOKING,
  },
  {
    label: "lbl_menu_schedule",
    enable: true,
    permissions: [],
    url: pathUrl.SCHEDULE,
  },
  {
    label: "lbl_menu_booking_price",
    enable: true,
    permissions: [],
    url: pathUrl.BOOKING_PRICE.INDEX,
  },

]



