export const pathUrl = {
  BOOKING: "/",
  SCHEDULE: "/schedule",
  BOOKING_PRICE: {
    INDEX: "/booking-price",
    DETAIL: "/booking-price/detail",
  },
  PAGE_500: "/page-500",
  PAGE_400: "/page-400",
  MASTER: "/master",
  EMPTY: "",
};



