import moment from "moment";

export const utils = {
  stringToNumber,
  formatCurrency,
  isStringOk,
  stringToASCII,
  formatDatetime,
  combineDateAndTime,
  getDayOfWeeks
};


function formatCurrency(val: any, prefix: string = "") {
  if (Number.isNaN(Number.parseFloat(val))) {
    val = 0;
  }
  let value = val
    ? `${val}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",")
    : `0`;
  return `${prefix}${value}đ`;
}

function stringToNumber(val: string) {
  let result: any = `${val || "0"}`.replace(/\$\s?|(,*)/g, '');
  return result - 0;
}

function isStringOk(s: string) {
  return s !== null && s !== "null" && s !== undefined && s !== "" && s !== `""` && s !== "undefined";
}

function formatDatetime(value: string) {
  return value;// moment(value, "")
}


function stringToASCII(str: string) {
  try {
    return str.toLowerCase()
      .replace(/[àáảãạâầấẩẫậăằắẳẵặ]/g, 'a')
      .replace(/[èéẻẽẹêềếểễệ]/g, 'e')
      .replace(/[đ]/g, 'd')
      .replace(/[ìíỉĩị]/g, 'i')
      .replace(/[òóỏõọôồốổỗộơờớởỡợ]/g, 'o')
      .replace(/[ùúủũụưừứửữự]/g, 'u')
      .replace(/[ỳýỷỹỵ]/g, 'y')
  } catch (e) {
    return ''
  }
}

function combineDateAndTime(date: any, time: any) {
  return moment(date).set(
    {
      hour: moment(time, 'HH:mm:ss').hour(),
      minute: moment(time, 'HH:mm:ss').minute(),
      second: moment(time, 'HH:mm:ss').second(),
    }
  );
}


/**
 * Get all dates of a specific day of the week within a given date range.
 * @param {number} dayOfWeek - The day of the week (0-6, where 0 is Sunday)
 * @param {Date} fromDate - The start date of the range
 * @param {Date} toDate - The end date of the range
 * @returns {Date[]} An array of dates matching the specified day of the week
 */
function getDayOfWeeks(dayOfWeek: number, fromDate: Date, toDate: Date) {
  // Initialize an empty array to store the matching dates
  const lstDayOfWeek = [];
  // Set the current date to the start of the range
  let currentDate = new Date(fromDate);

  // Loop through each day in the date range
  while (currentDate <= new Date(toDate)) {
    // Get the day of the week for the current date
    const currentWeekDay = currentDate.getDay();
    // If the current day matches the specified day of the week
    if (dayOfWeek == currentWeekDay) {
      // Add the current date to the array
      lstDayOfWeek.push(new Date(currentDate));
    }
    // Move to the next day
    currentDate.setDate(currentDate.getDate() + 1);
  }
  // Return the array of matching dates
  return lstDayOfWeek;
}