/* eslint-disable eqeqeq */
import { Button, Drawer, Flex, Image, } from "antd";
import React, { Component } from "react";
import { LocalizeContextProps, withLocalize } from "react-localize-redux";
import { LangEnum } from "../../constants/enums";
import { IMAGE } from "../../assets/images";
import { Header } from "antd/es/layout/layout";
import { MenuUnfoldOutlined, MenuFoldOutlined } from '@ant-design/icons';
import AppMenu from "../app-menu";

type IProps = LocalizeContextProps & {
  lang: any,
  formatDatetime: any,
  onChangeLang: (newLang: string) => void;
  onChangeDatetime: (newDatetime: string) => void;
};

interface IState {

  collapsed: boolean,
};

class AppHeader extends Component<IProps, IState> {
  constructor(props: any) {
    super(props);
    this.state = {
      collapsed: false,
    }
  }
  onChangeCollapsed(value: boolean) {
    this.setState({
      collapsed: value
    })
  }

  render() {

    let { collapsed, } = this.state;
    let { lang, onChangeLang, } = this.props;

    return (
      <React.Fragment>
        <Header className="app-header">
          <Flex wrap gap="small" justify="space-between" align="center" className="flex-header">
            <div className="collapsed-logo">
              <Button
                type="text"
                className="icon-collapsed"
                icon={collapsed ? <MenuUnfoldOutlined /> : <MenuFoldOutlined />}
                onClick={() => this.onChangeCollapsed(!collapsed)}
              />
            </div>
            <Flex gap={30} align="center">
              {lang == LangEnum.VI && (
                <div className="lang-group">
                  <Image loading='lazy' onClick={() => onChangeLang(LangEnum.EN)} preview={false} src={IMAGE.logoVi} />
                  <div><strong>VN</strong></div>
                </div>
              )}
              {lang == LangEnum.EN && (
                <div className="lang-group">
                  <Image loading='lazy' onClick={() => onChangeLang(LangEnum.VI)} preview={false} src={IMAGE.logoEn} />
                  <div><strong>EN</strong></div>
                </div>
              )}
            </Flex>
          </Flex>
        </Header>
        <Drawer
          title={
            <div className="logo">
              <Image loading='lazy' src={IMAGE.logo} preview={false} />
            </div>
          }
          size="default"
          placement="left"
          width={200}
          closable={false}
          onClose={(_) => this.onChangeCollapsed(!collapsed)}
          open={collapsed}
          className="drawer-menu"
        >
          <AppMenu mode="inline" />
        </Drawer>
      </React.Fragment>
    );
  }
}
export default withLocalize(AppHeader)
